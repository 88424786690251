import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiMassCommunicationService {

  apiUri = '/api/mass-communication/topics'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }

  get(id) {
    return this.apiService.get(`${this.apiUri}/${id}`);
  }

  topicDeleteAllSubscribers(id) {
    return this.apiService.delete(`${this.apiUri}/${id}/subscribers`);
  }

  addSubscribers(id, userIds) {
    let body = {
      UserIds: userIds
    }
    return this.apiService.post(`${this.apiUri}/${id}/subscribers`, body);
  }

  sendUpdate(id, updateText) {
    let body = {
      Text: updateText
    }
    return this.apiService.post(`${this.apiUri}/${id}/update`, body);
  }

  create(data) {
    return this.apiService.post(this.apiUri, data);
  }

  update(id, data) {
    return this.apiService.put(`${this.apiUri}/${id}`, data);
  }


}
