import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {RequestApiService} from "../../../services/api/request/request-api.service";
import {ActivatedRoute} from "@angular/router";
import {OptionsApiService} from "../../../services/api/options/options-api.service";
import {DialogService} from "../../../services/dialog-service/dialog.service";
import {FilterCreateEditBladeComponent} from "../../../shared/filter-create-edit-blade/filter-create-edit-blade.component";
import {RequestChangeTypeModalComponent} from "./request-change-type-modal/request-change-type-modal.component";
import {RequestLinkModalComponent} from "./request-link-modal/request-link-modal.component";
import {RequestAuditModalComponent} from "./request-audit-modal/request-audit-modal.component";
import {RequesterSearchModalComponent} from "../../../shared/requester-search-modal/requester-search-modal.component";
import {CustomerServiceSearchModalComponent} from "../../../shared/customer-service-search-modal/customer-service-search-modal.component";
import {RequestOverridePriorityModalComponent} from "./request-override-priority-modal/request-override-priority-modal.component";
import {EnvironmentService} from "../../../services/environment/environment.service";
import {AttachmentService} from "../../../services/api/attachment/attachment.service";
import {RequestFollowersModalComponent} from "./request-followers-modal/request-followers-modal.component";
import {RequestShowLinkModalComponent} from "./request-show-link-modal/request-show-link-modal.component";
import { interval, Subscription } from 'rxjs';
import {RequestChangeService} from "../../../services/api/request/request-change/request-change.service";
import {RequestServiceModalComponent} from "./request-service-modal/request-service-modal.component";
import {AuthApiService} from '../../../services/api/authentication/auth-api-service';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {RequestSelectTaskListComponent} from './request-select-task-list/request-select-task-list.component';
import { TasksComponent } from '../tasks/tasks.component';
import { UserPermissionsService } from 'src/app/services/user-permissions/user-permissions.service';
import { ApiAdminUsersService } from 'src/app/services/api/admin/admin-users-api/admin-users-api.service';
import { ApiAdminGroupsService } from 'src/app/services/api/admin/admin-groups-api/admin-groups-api.service';
import {InstanceParamsService} from "../../../services/instance-params/instance-params.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  @Input() data; // create // filterId // requestTypeId (Required on create)
  @Input() compactView = false; // create // filterId // requestTypeId (Required on create)

  @ViewChild(TasksComponent,{ static: false }) tasksComponent: TasksComponent;

  htmlBody = '<b>Default Html</b>Hey......'

  public showNoLongerAccessTicketError = false;

  public requestId;
  public requestDetails;
  public requestThread;
  public requestOptions;

  private isLoadingDetails;
  private isLoadingThread;
  private isLoadingOptions;

  public environmentParams;

  public showEditSubjectBody = false;
  public selectedThreadId = 1;

  public uploadFile;
  public decodedTaskList = [];

  public realTimeTimer;

  public realTimeInitiated;
  public realTimeOnlineUsers = [];
  public realTimeLastUpdated;

  public savingTaskListInProgress = false;

  public selectedTab = 'details';

  isaddTask:boolean = true;
  public userPermissionsSubscription;
  public accessPermissions;

  public changeState: string = '';
  public changleStateLevel;
  public changedField=[];
  public FieldType1Fields=[];
  public FieldType2Fields=[];
  public isLoadingRight:boolean = false;
  public requesterId;
  public modelValueChanged = false;
  public groupValueChanged = false;
  public agentValueChanged = false;
  public requesterValueChanged = false;
  public impactValueChanged = false;
  public urgencyValueChanged = false;
  public priorityValueChanged = false;
  public managerValueChanged = false;
  public statusValueChanged = false;
  public changedFieldtype2Arr = [];
  public customerValueChanged = false;
  public locationValueChanged = false;
  public serviceValueChanged = false;

  private detailsLoaded = false;
  private optionsLoaded = false;

  instanceParams: any;
  processingFollow = false;
  safeHtml: SafeHtml;

  constructor(
    private route: ActivatedRoute,
    private requestApi: RequestApiService,
    private optionsApi: OptionsApiService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private environmentService: EnvironmentService,
    private attachmentService: AttachmentService,
    private changeApi: RequestChangeService,
    private authService: AuthenticationService,
    private userPermissions: UserPermissionsService,
    private apiAdminUsersService: ApiAdminUsersService,
    private apiAdminGroupsService: ApiAdminGroupsService,
    private instanceParamsService: InstanceParamsService,
    private sanitizer: DomSanitizer) {

    this.environmentService.environmentVariablesSubscription.subscribe(data => this.environmentParams = data);

    userPermissions.refreshUserPermissions();
    userPermissions.userPermissionsSubscription.subscribe(data => {
      this.userPermissionsSubscription = data
      if(this.userPermissionsSubscription)
        {
          this.getAccessDetails(this.userPermissionsSubscription.AgentId);
        }
    });
  }

  ngOnInit(): void {

    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });

    this.route.paramMap.subscribe(params => {
      this.requestId = this.data ?? params.get("RequestId");

      if(this.requestId === ""  || this.requestId === null){
        return;
      }

      this.getRequestDetails(true);
      this.getRequestThread(true);
      this.getRequestOptions(true);
      this.refreshRealTimeStats();
    })

    // Start the real time timer and send data back to the API every 10 seconds
    this.realTimeTimer = interval(10000);
    this.realTimeTimer = interval(10000).subscribe(poll => this.refreshRealTimeStats());
  }
  getAccessDetails(agentId){
    this.apiAdminUsersService.get(agentId).then(response => {
      this.accessPermissions = response;
    })
  }

  addTask() {
    this.isaddTask = false;
    if (this.tasksComponent) {
      this.tasksComponent.addNewTask(this.requestDetails);
      this.tasksComponent.childChanges.subscribe((newValue) => {
      this.isaddTask = newValue
     this.refreshData();
      });
    }
  }

  ngOnDestroy() {
    // Destroy the timer
    this.realTimeTimer.unsubscribe();
  }

  isLoading(){
    return (this.isLoadingDetails === true || this.isLoadingThread === true || this.isLoadingOptions === true);
  }

  approveChange(){
    this.changeApi.approve(this.requestId).then(response => this.refreshData())
  }

  rejectChange(){
    this.changeApi.reject(this.requestId).then(response => this.refreshData())

  }

  hasRequestBeenUpdated(){
    let realTimeLastUpdated = new Date(this.realTimeLastUpdated);
    let loadedLastUpdated = new Date(this.requestDetails.DateTimeUpdated);

    if(realTimeLastUpdated > loadedLastUpdated) {
      return true
    } else {
      return false;
    }
  }

  changeBody(){
    this.htmlBody = 'New content? <u>UNDERLINED STUFF...</u>'
  }

  isCmdbEnabled(): boolean {
    return this.requestDetails.CmdbEnabled;
  }

  showAddtask(): void {
    this.selectedTab = 'details';
    this.isaddTask = true;

  }

  private refreshRealTimeStats(){
    this.requestApi.getRealTime(this.requestId).then(response => {
      this.realTimeOnlineUsers = response.OnlineUsers;
      this.realTimeLastUpdated = response.RequestLastUpdated;
      this.realTimeInitiated = true;
    })
  }

  private sanitizeHtml() {
    if (SdeskHelper.isNotNullOrEmpty(this.requestDetails)  && 
    SdeskHelper.isNotNullOrEmpty(this.requestDetails.RequestBody)) {
    this.requestDetails.RequestBody = this.sanitizer.bypassSecurityTrustHtml(this.requestDetails.RequestBody);
    }
  }
  private getRequestDetails(showLoading = false) {

    if(showLoading){
      this.isLoadingDetails = true;
    }

    this.requestApi.get(this.requestId).then(response => {
      this.requestDetails = response;
      this.processingFollow = false;
            // Initialize tempValue for custom fields
            this.requestDetails.CustomFields.forEach(field => {

              if (field.FieldType == 3) {
                if (field.Value == null) {
                  field.Value = this.ConvertToValidDate(field.Value);
                }
                else {

                  field.Value = this.formatDate(field.Value);
                  console.log("normal date",field.Value)
                  // field.Value = new Date(normaldate);
                }
              }
              else {
                field.Value = field.Value;
              }
          });

      // Separate fields with and without RequestTypeId
      const withRequestTypeId = this.requestDetails.CustomFields.filter(item => item.RequestTypeId !== null);
      const withoutRequestTypeId = this.requestDetails.CustomFields.filter(item => item.RequestTypeId === null);

      withRequestTypeId.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

      this.requestDetails.CustomFields = [...withRequestTypeId, ...withoutRequestTypeId];

      this.filterCustomfields(this.requestDetails);
      // this.filteragents(this.requestDetails);
      this.isLoadingDetails = false;
      this.detailsLoaded = true;
      this.generateImages();
      this.sanitizeHtml();

      // Initialize tempValue for custom fields
      this.requestDetails.CustomFields.forEach(field => {
        field.tempValue = field.Value;
      });
      console.log('this.requestDetails', this.requestDetails)

      this.decodedTaskList = JSON.parse(response.TaskList);

      // Unsubscribe from the real time timer if the ticket is a chat - chat has its own real timer
      if(this.requestDetails.ChatConversationId){
        if(this.realTimeTimer){
          this.realTimeTimer.unsubscribe();
        }
      }

      if(this.requestDetails.Type === 'Change Request' ){
        //convert all dates type to Date()
        this.requestDetails.ChangeManagement.Planning_ScheduledStart = this.ConvertToValidDate(this.requestDetails.ChangeManagement.Planning_ScheduledStart);
        this.requestDetails.ChangeManagement.Planning_ScheduledEnd = this.ConvertToValidDate(this.requestDetails.ChangeManagement.Planning_ScheduledEnd );
        this.requestDetails.ChangeManagement.Requested_Date=this.ConvertToValidDate(this.requestDetails.ChangeManagement.Requested_Date);
        this.requestDetails.ChangeManagement.Planning_DowntimeStart=this.ConvertToValidDate(this.requestDetails.ChangeManagement.Planning_DowntimeStart);
        this.requestDetails.ChangeManagement.Planning_DowntimeEnd=this.ConvertToValidDate(this.requestDetails.ChangeManagement.Planning_DowntimeEnd);
        this.requestDetails.ChangeManagement.Actual_StartDate=this.ConvertToValidDate(this.requestDetails.ChangeManagement.Actual_StartDate);
        this.requestDetails.ChangeManagement.Actual_EndDate=this.ConvertToValidDate(this.requestDetails.ChangeManagement.Actual_EndDate);

        //Filter the null values
        if (!this.requestDetails.ChangeManagement.Planning_ScheduledStart || !this.requestDetails.ChangeManagement.Planning_ScheduledEnd || !this.requestDetails.ChangeManagement.Requested_Date
          ||!this.requestDetails.ChangeManagement.Planning_DowntimeStart||!this.requestDetails.ChangeManagement.Planning_DowntimeEnd || !this.requestDetails.ChangeManagement.Actual_StartDate || !this.requestDetails.ChangeManagement.Actual_EndDate) {
            this.handleChangeStateUpdate();
            return null;
        }

      }

    this.checkAndFilterGroups();

    }, error => {

      if(error == "You don't have permission to view this ticket"){
        this.showNoLongerAccessTicketError = true;
        this.isLoadingDetails = false;
        this.isLoadingOptions = false;
        this.isLoadingThread = false;
      }

      console.error('Error occured',error);
    })
  }

formatDate(inputDate: string) {
  const [year, month, day] = inputDate.split('-').map(Number);
  console.log("step1 date",[year, month, day])
  const dateStr = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
  const date = new Date(dateStr);
  const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  console.log("utcDate",utcDate)
  return utcDate;
}

  private getRequestThread(showLoading = false) {

    if(showLoading){
      this.isLoadingThread = true;
    }

    this.requestApi.getThread(this.requestId).then(response => {
      this.requestThread = response;
      this.isLoadingThread = false;

    }, error => {
      console.error(error);
    })
  }

  saveTaskList(){
    this.savingTaskListInProgress = true;
    this.requestApi.saveTaskList(this.requestId, this.decodedTaskList).then(response => this.savingTaskListInProgress = false)
  }

  generateImages(){

    const authToken = this.authService.returnAuthToken();
    let str = this.requestDetails.RequestBody;

    this.requestDetails.Attachments.forEach(attachment => {
      const name = attachment.AttachmentName;
      const id = attachment.AttachmentId;


      // const regex = /src=".*cid:/+name+/@.*?"/;
      //
      // const reg = `/src=".*cid:${name}@.*?"/`
      // // const reg = `/src=".*cid:${name}@(.*)"/`

      var regex = new RegExp("src=\".*cid:" + name + "@.*?\"");


      console.log(regex);

      str = str.replace(regex, `src="/download/${id}?auth=${authToken}"`);


    })
    this.requestDetails.RequestBody = str;

  }

  private getRequestOptions(showLoading = false) {

    if(showLoading){
      this.isLoadingOptions = true;
    }

    this.optionsApi.RequestOptions(this.requestId).then(response => {
      this.requestOptions = response;
      console.log("RequestOptions :: ", this.requestOptions);
      this.isLoadingOptions = false;
      this.optionsLoaded = true;

      this.checkAndFilterGroups();

    }, error => {
      console.error(error);
    })
  }

  filterOptionGroups() {

    console.log("this.filteredGroups :: ", this.requestOptions.group);

    // Check if currently assigned group is in the requestOptions
    let assignedGroup = this.requestOptions.group.find(group => group.id === this.requestDetails.GroupId);

    // If the assigned group is not found, an entry is created
    if (!assignedGroup) {
      assignedGroup = {
        id: this.requestDetails.GroupId,
        name: this.requestDetails.CurrentGroup,
        enabled: this.requestDetails.CurrentGroupEnabled
      };

      this.requestOptions.group.push(assignedGroup);
    }
      // Duplicates removed
      const uniqueGroups = Array.from(new Set(this.requestOptions.group.map(group => group.id)))
      .map(id => this.requestOptions.group.find(group => group.id === id));

      this.requestOptions.group = uniqueGroups;

      console.log("this.filteredGroups :: ", this.requestOptions.group);
  }

  checkAndFilterGroups() {
    if (this.detailsLoaded && this.optionsLoaded) {
      console.log('checkAndFilterGroups is true')
      this.filterOptionGroups();
    }
  }

  refreshData(){
    this.getRequestDetails();
    this.getRequestThread();
    this.getRequestOptions();
    this.changedField = [];
  }

  refreshRequestDetails(){
    console.log("calling refreshRequestDetails");
    this.getRequestDetails();
    this.isLoadingRight = false;
  }

  openServiceModal(){
    this.dialogService.createDialog(RequestServiceModalComponent, this.requestDetails.ServiceId, this.viewReference)
      .then(dialogSaved => {
        this.refreshData();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  convert(){
    this.dialogService.createDialog(RequestChangeTypeModalComponent, this.requestId, this.viewReference)
      .then(dialogSaved => {
        this.refreshData();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  openFollowersModal(){
    this.dialogService.createDialog(RequestFollowersModalComponent, this.requestDetails.Followers, this.viewReference)
      .then(dialogSaved => {
      }, dialogCancelled => {
      })
  }

  findTaskListModal(){
    this.dialogService.createDialog(RequestSelectTaskListComponent, this.requestId, this.viewReference)
      .then(dialogSaved => {
        this.refreshData();
      }, dialogCancelled => {
      })
  }

  openShowLinksModal(){
    this.dialogService.createDialog(RequestShowLinkModalComponent, this.requestDetails, this.viewReference)
      .then(dialogSaved => {
        this.refreshData();
      }, dialogCancelled => {
      })
  }

  link(){
    this.dialogService.createDialog(RequestLinkModalComponent, {requestId: this.requestId, requestTypeId: this.requestDetails.TypeId}, this.viewReference)
      .then(dialogSaved => {
        this.refreshData();
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  incompleteTaskCount(){
    if(!this.decodedTaskList){
      return 0;
    }
    return this.decodedTaskList.filter(task => task.Completed != true).length;
  }

  auditLog(){
    this.dialogService.createDialog(RequestAuditModalComponent, this.requestId, this.viewReference)
      .then(dialogSaved => {
        // Do Nothing
      }, dialogCancelled => {
        // Do Nothing
      })
  }

  toggleFollow(){
    this.processingFollow = true;
    this.requestApi.toggleFollow(this.requestId).then(result => {
      this.refreshData();
    })
  }

  overRidePriorityDialog(){
    this.dialogService.createDialog(RequestOverridePriorityModalComponent, this.requestId, this.viewReference)
      .then(dialogSaved => {
        this.modelValueChanged = true;
        this.requestDetails.priorityReason = dialogSaved;
      }, dialogCancelled => {
        console.log(dialogCancelled)
      })
  }

  downloadAttachment(attachmentId){
    this.attachmentService.downloadAttachment(attachmentId);
  }

  setCustomFieldValue(data){
    let customFieldId = data.customFieldId;
    let customFieldOptionId = data.selected;
    this.requestApi.updateCustomField(this.requestId, customFieldId, customFieldOptionId).then(success => {
      this.refreshData();
    })
  }

  saveCustomFieldTextValue(customField){
    this.requestApi.updateCustomField(this.requestId, customField.Id, customField.tempValue).then(success => {
      customField.Value = customField.tempValue;
      this.refreshData();
    })
  }

  setService(serviceId){
    this.requestApi.updateService(this.requestId, serviceId).then(success => {
      this.serviceValueChanged = false;
    })
  }

  setCustomerLocation(customerLocationId){
    this.requestApi.updateCustomerLocation(this.requestId, customerLocationId).then(success => {
      this.locationValueChanged = false;
    })
  }

  setCustomer(customerId){
    this.requestApi.updateCustomer(this.requestId, customerId).then(success => {
      this.customerValueChanged =false;
    })
  }

  setImpact(){
    this.requestApi.updateImpact(this.requestId, this.requestDetails.ImpactId).then(success => {
      this.impactValueChanged = false;
    })
  }

  setUrgency(){
    this.requestApi.updateUrgency(this.requestId, this.requestDetails.UrgencyId).then(success => {
      this.urgencyValueChanged = false;
    })
  }

  setPriority(){
    this.requestApi.updatePriority(this.requestId, this.requestDetails.PriorityId).then(success => {
      this.priorityValueChanged = false;
    })
  }

  setStatus(){
    this.requestApi.updateStatus(this.requestId, this.requestDetails.StatusId).then(success => {
      this.statusValueChanged = false;
    })
  }

  updateSubjectBody(){
    this.requestApi.updateSubjectBody(this.requestId, this.requestDetails.RequestSubject, this.requestDetails.RequestBody).then(success => {
      this.refreshData();
      this.showEditSubjectBody = false;
    })
  }

  reOpenTicket(){
    this.requestApi.reOpen(this.requestId).then(response => this.refreshData());
  }

  setAgent(agentId){
    this.requestApi.updateAgent(this.requestId, agentId).then(success => {
      // this.refreshData();
      this.agentValueChanged =false;
    })
  }

  setChangeManager(ChangeManagement){
    if(ChangeManagement.ChangeManagerUserId !== null)
    {
    this.changeApi.setChangeManager(this.requestId, this.requestDetails.ChangeManagement.ChangeManagerUserId).then(success => {
      // this.refreshData();
      this.managerValueChanged = false;
    })
    }
  }

  setGroup(groupId,requestDetails){
    this.requestApi.updateGroup(this.requestId, groupId).then(success => {
      // this.refreshData();
      if (requestDetails.AgentId) {
        this.setAgent(requestDetails.AgentId);
      }
      this.groupValueChanged = false;
    })
  }

  filterCustomfields(requestDetails) {
    this.FieldType1Fields = requestDetails.CustomFields.filter(field => field.FieldType == 1 || field.FieldType == 3);
    this.FieldType2Fields = requestDetails.CustomFields.filter(field => field.FieldType == 2)
  }
  onValueChanged(type,requestDetails) {
    this.modelValueChanged = true;
    if(type === 'group') {
      this.groupValueChanged = true;
      this.requestDetails.AgentId = "";
    }
    if(type === 'agent') {
      this.agentValueChanged = true;
      this.requestDetails.AgentId = requestDetails.AgentId;
    }
    if(type === 'impact') {
      this.impactValueChanged = true;
    }
    if(type === 'urgency') {
      this.urgencyValueChanged = true;
    }
    if(type === 'priority') {
      this.priorityValueChanged = true;
    }
    if(type === 'manager') {
      this.managerValueChanged = true;
    }
    if(type === 'status') {
      this.statusValueChanged = true;
    }
  }

  saveAllCustomFields(requestDetails) {
    console.log("saving request details", requestDetails)
    this.isLoadingRight = true;
    if (this.groupValueChanged == true) {
      if (requestDetails.GroupId) {
        this.setGroup(requestDetails.GroupId, requestDetails);
      }
    }
    if (this.agentValueChanged == true && this.groupValueChanged == false) {
      if (requestDetails.AgentId) {
        this.setAgent(requestDetails.AgentId);
      }
    }

    let filteredObjects = [];
    filteredObjects = this.FieldType1Fields.filter(obj => obj.valueChanged);
    filteredObjects.forEach(field => {
      let customField;
      if (field.FieldType == 3) {
        field.Value = this.convertdate(field.tempValue);
        field.tempValue = this.convertdate(field.tempValue);
        customField = field;
        console.log("field3date", customField)
      }
      else {
        customField = field;
      }
      this.saveCustomFieldTextValue(customField)
    })
    this.changedFieldtype2Arr = this.filterChangedfields(this.FieldType2Fields);
    if (this.changedField.length > 0) {
      this.changedFieldtype2Arr.forEach(field => {
        this.setCustomFieldValue(field);
      })
    }
    else {
      console.log("Field type 2 not updated")
    }
    if (this.priorityValueChanged == true && this.requestDetails.PriorityId !== null) {
      this.setPriority();
    }
    if (this.statusValueChanged == true) {
      this.setStatus();
    }

    if (this.requestDetails.RequesterId && this.requesterValueChanged == true) {
      this.requestApi.updateRequester(this.requestId, this.requestDetails.RequesterId).then(success => {
        this.requesterValueChanged = false;
        // this.refreshData();
      })
    }
    if (this.managerValueChanged == true && this.requestDetails.ChangeManagement) {
      this.setChangeManager(this.requestDetails.ChangeManagement);
    }
    if (this.customerValueChanged == true && this.requestDetails.CustomerId) {
      this.setCustomer(this.requestDetails.CustomerId);
    }
    if (this.locationValueChanged == true && this.requestDetails.CustomerLocationId) {
      this.setCustomerLocation(this.requestDetails.CustomerLocationId);
    }
    if (this.serviceValueChanged == true && this.requestDetails.ServiceId) {
      this.setService(this.requestDetails.ServiceId);
    }
    if (this.impactValueChanged == true && this.requestDetails.ImpactId) {
      this.setImpact();
    }
    if (this.urgencyValueChanged == true && this.requestDetails.UrgencyId) {
      this.setUrgency()
    }
    if (this.requestDetails.priorityReason) {
      this.overridePriority();
    }
    setTimeout(() => {
      this.refreshRequestDetails();
    }, 2000);
    this.modelValueChanged = false;
  }

  convertdate(valueDate) {
    let date = new Date(valueDate);
    // Adjust the date to the desired output format "YYYY-MM-DD"
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
    let day = String(date.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate", formattedDate);
    return formattedDate;
  }

  overridePriority()
  {
    this.requestApi.overridePriority(this.requestId, this.requestDetails.priorityReason).then(success => {
    })
  }

  filterChangedfields(FieldType2Fields) {
    let result = [];
    FieldType2Fields.forEach(obj1 => {
      let match = this.changedField.find(obj2 => obj2.customFieldId === obj1.Id);
      if (match && match.customFieldId !== obj1.Id) {
      } else if (match) {
        result.push(match);
      }
    });
    return result;
  }

  getNewValue(event) {
    this.changedField = this.changedField.filter(obj => obj.customFieldId !== event.customFieldId);
    this.changedField.push(event);
  }

  filteragents(requestDetails)
  {
    if(requestDetails.GroupId)
      {
    this.apiAdminGroupsService.get(requestDetails.GroupId)
      .then(r => {
        this.requestOptions.agent = r.Members.map(item => {
          return { id: item.Id, name: item.Name }
        })
      });

      }
  }
  onFocus(requestDetails){
   this.filteragents(requestDetails);
  }

  openUserSearchModal(){
    this.dialogService.createDialog(RequesterSearchModalComponent, null, this.viewReference)
      .then((dialogSaved: any) => {
        this.modelValueChanged = true;
        this.requesterValueChanged = true;
        const userId = dialogSaved?.userId;
        const customerId = dialogSaved?.customerId;
        const RequesterName = dialogSaved?.userName;
        const RequesterEmail = dialogSaved?.userEmail;
        const prefferedContactMethod = dialogSaved?.prefferedContactMethod;
        const contactMethod = dialogSaved?.userContact;

        if(userId) {
          this.requestDetails.RequesterId = userId;
          this.requestDetails.Requester = RequesterName;
          this.requestDetails.RequesterEmailAddress = RequesterEmail;
          this.requestDetails.RequesterPhoneNumber = contactMethod;
          this.requestDetails.RequesterPreferredContactMethod = prefferedContactMethod;
          // this.requestApi.updateRequester(this.requestId, userId).then(success => {
          //   this.refreshData();
          // })
          // console.log("requester details",userId,customerId,RequesterName,RequesterEmail,prefferedContactMethod,contactMethod)
        }

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })

  }

  openCustomerServiceSearchModal(){

    const inputData = {
      customerId: this.requestDetails.CustomerId,
      customerName: this.requestDetails.CurrentCustomer,
      customerLocationId: this.requestDetails.CustomerLocationId,
      cusLocationName:this.requestDetails.CurrentCustomerLocation,
      serviceId: this.requestDetails.ServiceId,
      serviceName:this.requestDetails.CurrentService
    };

    this.dialogService.createDialog(CustomerServiceSearchModalComponent, inputData, this.viewReference)
      .then((dialogSaved: any) => {
        // console.log("dialogoutput",dialogSaved)
        this.modelValueChanged = true;
          this.requestDetails.CurrentService = dialogSaved.serviceName;
          this.requestDetails.ServiceId = dialogSaved.serviceId;
          if(dialogSaved.serviceId)
          {
            this.serviceValueChanged= true;
          }

          this.requestDetails.CurrentCustomerLocation = dialogSaved.cusLocationName;
          this.requestDetails.CustomerLocationId = dialogSaved.customerLocationId;
          if(dialogSaved.customerLocationId)
            {
              this.locationValueChanged= true;
            }
          this.requestDetails.CurrentCustomer = dialogSaved.customerName;
          this.requestDetails.CustomerId = dialogSaved.customerId;
          if(dialogSaved.customerId)
            {
              this.customerValueChanged= true;
            }

      }, dialogCancelled => {
        console.log("dialogCancelled:"+dialogCancelled)
      })
  }

  handleChangeStateUpdate() {

    this.changleStateLevel = this.requestDetails.ChangeManagement.ChangeState;

    switch(this.changleStateLevel) {
      case 1:
        this.changeState = 'Opened';
        break;
      case 2:
        this.changeState = 'Planning';
        break;
      case 3:
        this.changeState = 'Scheduling';
        break;
      case 4:
        this.changeState = 'Under Approval';
        break;
      case 5:
        this.changeState = 'Implementation';
        break;
      case 6:
        this.changeState = 'Under Review';
        break;
      case 7:
        this.changeState = 'Pending Closure';
        break;
      case 8:
        this.changeState = 'Closed';
        break;
      default:
        this.changeState = '';
    }

  }

  //Filter the null dates
  ConvertToValidDate(date){
    if (!date) {
        return null;
    }

    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

}
