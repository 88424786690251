

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminUsersService} from "../../../../../../services/api/admin/admin-users-api/admin-users-api.service";
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {EnvironmentService} from "../../../../../../services/environment/environment.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";
import {UserPermissionsService} from "../../../../../../services/user-permissions/user-permissions.service";
import { InstanceParamsService } from '../../../../../../services/instance-params/instance-params.service';
import { AgentUserService } from '../../../../../../services/api/agent-user/agent-user.service';
@Component({
  selector: 'app-admin-users-create-edit-dialog',
  templateUrl: './admin-users-create-edit-dialog.component.html'
})

export class AdminUsersComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<void>();

  isLoading = true;
  isOptionsLoading = true;

  options;
  loadedId = null;

  loadedData;
  loadedUserData;
  environmentParams;

  selectedTab = 'details';

  dropdownSettings = {
    singleSelection: false,
    text: "Please select...",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'name',
    lazyLoading: true,
    badgeShowLimit: 4,
    classes: "sdesk-multi-select"
  };

  customFields;

  preferredContactMethods = [
    {id:1, name:'E-mail'},
    {id:2, name:'Phone'}
  ];

  customerPermissions = [
    {id:1, name:'Agent can see all tickets'},
    {id:2, name:'Agent can only see tickets linked to specific customers'}
  ];

  requestTypePermissions = [
    {id:1, name:'Agent can see all request types'},
    {id:2, name:'Agent can only see certain request types'}
  ];

  groupPermissions = [
    {id:1, name:'Agent can see all tickets'},
    {id:2, name:'Agent can only see tickets assigned to groups the they are a member of'}
  ];

  constructor(
    private apiAdminUsersService: ApiAdminUsersService,
    private environmentService: EnvironmentService,
    private optionsService: OptionsApiService,
    private userPermissionsService: UserPermissionsService,
    private instanceParamsService: InstanceParamsService,
    private agentUserService:AgentUserService
  ) {

    this.environmentService.environmentVariablesSubscription.subscribe(data => this.environmentParams = data);

    this.optionsService.AllOptions().then(response => {
      this.options = response;
      this.isOptionsLoading = false;
    })

  }


  ngOnInit(): void {
    this.getCurrentUserData();
    

    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
      this.getCustomFields();
    } else {
      // Load Default Data
      this.loadedData = {
        Name: '',
        EmailAddress: '',
        JobTitle: '',
        Department: '',
        LineManager: '',
        Admin: 0,
        Agent:0,
        CustomerId: null,
        AllowPortalLogin: 0,
        PhoneNumber: '+44',
        PreferredContactMethod: 1,
        AgentGroups: [],
        AgentPermission_Group: 1,
        AgentPermission_Customer: 1,
        AgentPermission_Type: 1,
        AgentPermission_SelectedCustomers: [],
        AgentPermission_SelectedRequestTypes: [],
        CustomFields: []
      }

      this.getCustomFields();


      this.isLoading = false;

    }
  }

  toggledAgent(){
    if(this.loadedData.Agent) {
      this.loadedData.CustomerId = null;
    }

    if(!this.loadedData.Agent) {
      this.loadedData.AgentGroups = [];
      this.loadedData.Admin = 0;
      this.loadedData.Reporting = 0;
      this.loadedData.ChangeManager = 0;
    }
  }

  setCustomFieldValue(customField, data) {
    
    const customFieldId = customField.CustomFieldId;

    if (customField.FieldType === 2) { // If FieldType is 2 (multiselect dropdown)
        const alreadyExists = this.loadedData.CustomFields.find(field => field.CustomFieldId === customFieldId);

        if (alreadyExists) {
            alreadyExists.Value = data.selected;
        } else {
            this.loadedData.CustomFields.push({
                CustomFieldId: customFieldId,
                Value: data.selected,
                FieldType: customField.FieldType
            });
        }
    } else if (customField.FieldType === 1) { // If FieldType is 1 (textbox)
        const alreadyExists = this.loadedData.CustomFields.find(field => field.CustomFieldId === customFieldId);

        if (alreadyExists) {
            alreadyExists.Value = data;
        } else {
            this.loadedData.CustomFields.push({
                CustomFieldId: customFieldId,
                Value: data,
                FieldType: customField.FieldType
            });
        }
    }

    console.log("loaded data", this.loadedData);
}

  getCustomFields(){
    this.apiAdminUsersService.getFullUserCustomField().then(response=>{
      this.customFields= response;
      this.loadedData.CustomFields= this.customFields      
    });
  }


  getData(){
    this.isLoading = true;
    this.apiAdminUsersService.get(this.loadedId).then(response => {
      this.loadedData = response;

      // Replace the T that we put in the phone number (Nasty Hack!)
      this.loadedData.PhoneNumber = this.loadedData.PhoneNumber ? this.loadedData.PhoneNumber.replace('T', '') : null;

      this.isLoading = false;
    })
  }

  getCurrentUserData(){
    this.isLoading = true;
    this.userPermissionsService.refreshUserPermissions();
    this.userPermissionsService.userPermissionsSubscription.subscribe(data => this.loadedUserData = data);
    this.isLoading = false;
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedData.PhoneNumber == "+44") {
      this.loadedData.PhoneNumber = null;
    }

    if(this.loadedId){
      this.apiAdminUsersService.update(this.loadedId, this.loadedData).then(response => {
        this.instanceParamsService.refreshInstanceParams();
        this.agentUserService.refreshUsers();
        this.saveEvent.emit(); 
        this.remove.emit("Saved");
        
      }).catch(error => {
        this.getData();
    });
    } else {
      this.apiAdminUsersService.create(this.loadedData).then(response => {
        this.instanceParamsService.refreshInstanceParams();
        this.agentUserService.refreshUsers();
        this.saveEvent.emit(); 
        // Return with newly created UserId
        this.remove.emit(response.Id);
        
      })
    }

  }
}
