<!--  Response SLA Tile  -->
<div *ngIf="requestDetails.Sla" class="sectionBox p-0">

  <!--   Responded   -->
  <div *ngIf="requestDetails.Sla.ResponseDateTime" class="row">
    <div class="col p-20">
      <h3 class="m-0 slaHeader">Response</h3>
      <span>Responded</span>
      <span>{{ requestDetails.Sla.ResponseOverdue ? ' - SLA Breached' : ' - Within SLA' }}</span>
    </div>
    <div class="col-3 d-flex align-items-center justify-content-center slaBox" [class.red]="requestDetails.Sla.ResponseOverdue" [class.green]="!requestDetails.Sla.ResponseOverdue">
      <i class="fas fa-check"></i>
    </div>
  </div>

  <!--   Not Responded   -->
  <div *ngIf="!requestDetails.Sla.ResponseDateTime && !requestDetails.Sla.ResolutionDateTime" class="row">

    <div class="col p-20">
      <h3 class="m-0 slaHeader">Response</h3>
      <span>Pending</span>
      <span>{{ requestDetails.Sla.ResponseOverdue ? ' - Overdue' : '' }}</span>
      <br>
      <span><strong>Due</strong> {{ requestDetails.Sla.ResponseDueDate | amCalendar }}</span>
    </div>

    <div class="col-3 d-flex align-items-center justify-content-center slaBox" [class.red]="requestDetails.Sla.ResponseOverdue" [class.grey]="!requestDetails.Sla.ResponseOverdue">
      <i class="far fa-hourglass"></i>
    </div>

  </div>

  <div *ngIf="!requestDetails.Sla.ResponseDateTime && requestDetails.Sla.ResolutionDateTime" class="row">

    <div class="col p-20">
      <h3 class="m-0 slaHeader">Response</h3>
      <span>No Response</span>
      <br>
    </div>

    <div class="col-3 d-flex align-items-center justify-content-center slaBox" [class.red]="requestDetails.Sla.ResponseOverdue" [class.grey]="!requestDetails.Sla.ResponseOverdue">
      <i class="far fa-times"></i>
    </div>

  </div>


</div>

<!--  Resolution SLA Tile  -->
<div *ngIf="requestDetails.Sla" class="sectionBox p-0">

  <!--   Paused   -->
  <div *ngIf="requestDetails.Sla.SlaPaused && !requestDetails.Sla.ResolutionDateTime" class="row">
    <div class="col p-20">
      <h3 class="m-0 slaHeader">Resolution</h3>
      <span>Pending - Paused</span>
    </div>
    <div class="col-3 d-flex align-items-center justify-content-center slaBox grey">
      <i class="fas fa-pause"></i>
    </div>
  </div>

  <!--   Resolved   -->
  <div *ngIf="requestDetails.Sla.ResolutionDateTime" class="row">
    <div class="col p-20">
      <h3 class="m-0 slaHeader">Resolution</h3>
      <span>Resolved</span>
      <span>{{ requestDetails.Sla.ResolutionOverdue ? ' - SLA Breached' : ' - Within SLA' }}</span>
    </div>
    <div class="col-3 d-flex align-items-center justify-content-center slaBox" [class.red]="requestDetails.Sla.ResolutionOverdue" [class.green]="!requestDetails.Sla.ResolutionOverdue">
      <i class="fas fa-check"></i>
    </div>
  </div>

  <!--   Not Resolved   -->
  <div *ngIf="!requestDetails.Sla.ResolutionDateTime && !requestDetails.Sla.SlaPaused" class="row">

    <div class="col p-20">
      <h3 class="m-0 slaHeader">Resolution</h3>
      <span>Pending</span>
      <span>{{ requestDetails.Sla.ResolutionOverdue ? ' - Overdue' : '' }}</span>
      <br>
      <span><strong>Due</strong> {{ requestDetails.Sla.ResolutionDueDate | amCalendar }}</span>
    </div>

    <div class="col-3 d-flex align-items-center justify-content-center slaBox" [class.red]="requestDetails.Sla.ResolutionOverdue" [class.grey]="!requestDetails.Sla.ResolutionOverdue">
      <i class="far fa-hourglass"></i>
    </div>

  </div>

</div>
