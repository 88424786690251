import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TasksDialogComponent } from './tasks-dialog/tasks-dialog.component';
import { ModalConfirmationComponent } from '../../../shared/modal-confirmation/modal-confirmation.component';
import { TasksApiService } from '../../../services/api/tasks/tasks-api-service.service';
import { DialogService } from '../../../services/dialog-service/dialog.service';
import { OptionsApiService } from '../../../services/api/options/options-api.service';
import { RequestApiService } from '../../../services/api/request/request-api.service';
import { ApiAdminGroupsService } from 'src/app/services/api/admin/admin-groups-api/admin-groups-api.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  @Input() requestId = null;
  @Output() refreshParentComponent = new EventEmitter<any>();
  @Output() childChanges: EventEmitter<any> = new EventEmitter();
  data;
  options;
  isLoading = true;
  requestDetails;
  isShowstatus: boolean = true;
  newTask;
  isShowinline: boolean = false;
  agentsEdit = [];
  agentsDisplay = [];

  constructor(private requestApi: RequestApiService, private optionsApi: OptionsApiService, private tasksApiService: TasksApiService, private dialogService: DialogService, private viewReference: ViewContainerRef,
    private apiAdminGroupsService: ApiAdminGroupsService
  ) {
  }

  ngOnInit(): void {
    this.getRequestDetails();
    this.dialogService.modalEventEmitter.subscribe((data) => {
      // Handle the emitted event from the modal service
      this.getData();
      this.refreshParentComponent.emit();
    });
    this.optionsApi.AllOptions().then(response => this.options = response);
    this.getData();

  }

  refreshParent() {
    this.refreshParentComponent.emit();
  }

  getData(showLoading = true) {
    this.isLoading = showLoading;

    if (!this.requestId) {
      this.tasksApiService.listMyTasks().then(response => {
        this.data = response.map(task => ({ ...task, Instructions: this.stripHtml(task.Instructions), isEditing: false }));

        this.isLoading = false;
      })
    } else {
      this.tasksApiService.listRequestTasks(this.requestId).then(response => {
        this.data = response;
        this.isLoading = false;
      })
    }
  }

  deletefromArray(index) {
    this.childChanges.emit(true);
    this.isShowstatus = true;
    this.isShowinline = false;
  }
  getRequestDetails() {
    this.isLoading = true;
    if (this.requestId != null) {
      this.requestApi.get(this.requestId).then(response => {
        this.requestDetails = response;
        this.isLoading = false
      }
      )
    }
  };

  create() {
    // Replace null with Component
    this.dialogService.createDialog(TasksDialogComponent, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  markComplete(id) {
    this.tasksApiService.markComplete(id).then(response => this.getData(false))
  }

  markPending(id) {
    this.tasksApiService.markPending(id).then(response => this.getData(false))
  }

  addNewTask(requestDetails) {
    this.isShowstatus = false;
    this.isShowinline = true;
      this.newTask = {
      TaskName: '',
      Instructions: '',
      RequestId: this.requestId,
      TypeId: requestDetails.TypeId,
      GroupId: null,
      LocalId: requestDetails.LocalId,
      Completed: false,
      SendCompletionEmail: false,
      ShowCompletionEmailDialog: false,
      isEditing: true
    };
  }

  filterAgentsdata(newtask, event) {
    console.log('task', newtask)
    console.log('event', event)
    this.newTask.AgentId = "";

    if (newtask.GroupId) {

      this.apiAdminGroupsService.get(newtask.GroupId)
        .then(r => {
          this.agentsEdit = r.Members.map(item => {
            return { id: item.Id, name: item.Name }
          })
        });
      this.isLoading = false;
    }
  }

  toggleEdit(task: any, isEditing: boolean) {
    task.isEdittable = isEditing;
    this.filterAgents(task);
  }

  toggleAgents(task, index) {
    this.data[index].AgentId = '';
    this.setGroup(task.Id,task.GroupId);
    this.filterAgents(task);
  }
  filterAgents(task) {
    if(task.GroupId)
      {
    this.apiAdminGroupsService.get(task.GroupId)
      .then(r => {
        this.options.agent = r.Members.map(item => {
          return { id: item.Id, name: item.Name }
        })
      });
    this.isLoading = false;
      }
  }

  setGroup(taskId, groupID) {
    if (taskId === undefined && groupID) {
      if (this.data.length > 0) {
        this.data[0].GroupId = groupID;
      }

    } else {
      if(groupID)
        {
          this.tasksApiService.assignToGroup(taskId, groupID).then(response => console.log('updated'))
        }
    }
  }

  setAgent(id, agentId,status) {
    if (agentId) {
      this.tasksApiService.assignToAgent(id, agentId).then(response =>{
        if(status==='new')
          {
            this.getData()
          }
        else
        {
          console.log('updated agent');
        }
      })
    }
  }

  saveTaskChanges(task: any) {
    if (task.Id) {
      this.tasksApiService.updateTask(task.Id, task).then(() => {

        task.isEditing = false;
        task.isEdittable = false;
        this.childChanges.emit(true);
        this.getData()

      }).catch(error => {
        console.error('Failed to update task', error);
      });
    }
  }
  
  savenewTaskChanges(task) {
    this.isShowinline = false;
   
    this.tasksApiService.create(task).then(response => {
      this.isShowstatus = true;
      task.Id = response;
      task.isEditing = false;
      if(task.AgentId)
        {
          this.setAgent(response, task.AgentId,'new');
        }
    
      task.isEdittable = false;

      this.childChanges.emit(true);
      this.getData();
    }).catch(error => {
      console.error('Failed to create task', error);
    });
  }

  sendEmail(id, emailContents, requestId) {
    this.requestApi.threadReply(requestId, emailContents, [], 1, null, false).then(response => {
      this.markComplete(id);
      this.refreshParent();
    });

  }

  stripHtml(html) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(TasksDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.tasksApiService.delete(id).then(response => this.getData())
        this.childChanges.emit(true);
      }, cancelled => null)
  }

}
