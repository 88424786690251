<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right size="blade-xlarge">

  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

  <div *ngIf="!isLoading" class="row" style="height: 100%">
    <div class="col-3" style="
    border-right: solid #dfdfdf 1px;
">
      <div class="m-r-10">
        <div class="p-10 p-b-0">
          <i (click)="addSubscribers()" class="fas fa-plus fa-2x float-end divLink" aria-hidden="true" style="color: #3e6175;"></i>
          <h4>Subscribers ({{this.loadedData.Subscribers.length}})</h4>
        </div>
        <hr>

        <input [(ngModel)]="searchSubscribersTerm" class="form-control" name="searchTerm" placeholder="Search...">


        <div class="table-responsive p-0">
          <table class="table align-middle tableStyle m-b-5">
            <tbody>
            <tr *ngFor="let subscriber of this.filteredSubscribers()" class="divLink">
              <td><strong>{{subscriber.UserName}}</strong> ({{subscriber.UserEmailAddress}})</td>
            </tr>
            </tbody>
          </table>
        </div>

        <p class="text-muted text-center m-0">
          {{this.filteredSubscribers().length}} of {{this.loadedData.Subscribers.length}} results
        </p>

        <div class="btn-group m-t-5 sdesk-button-group-100">
          <a class="btn btn-primary divLink" (click)="removeAllSubscribers()">Remove all subscribers</a>
        </div>
      </div>
    </div>

    <div class="col-9">
      <div class="m-l-10">
        <div class="p-10 p-b-0">
          <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
          <h4>{{loadedData.TopicSubject}}
            <i (click)="editSubjectName(loadedId)" class="fas fa-pen divLink fadeIcon m-l-10"></i>
          </h4>
        </div>
        <hr>
        <div class="container p-0">
          <div class="row">
            <quill-editor [(ngModel)]="newThreadContent" [styles]="{height: '150px'}" placeholder=""></quill-editor>
            <div *ngIf="!sendingInProgress" id="actionContentButtons" class="float-end m-t-5">
              <button class="btn btn-primary divLink float-end" [disabled]="!newThreadContent" (click)="sendUpdate()">Send Update</button>
            </div>
          </div>

          <div class="p-10 p-b-0">
            <h4>Updates</h4>
          </div>
          <hr>

          <div *ngFor="let threadItem of this.loadedData.ThreadItems" class="threadItem outboundEmail">
            <span>
              <strong>{{threadItem.AgentName}}</strong>
              <span class="float-end"><i class="far fa-clock m-r-5"></i> <app-friendly-time [utcTimeData]="threadItem.CreatedDateTime" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></span>
            </span>

            <div [innerHTML]="getSafeHtml(threadItem.Body)"></div>

            <!--            <div *ngFor="let attachment of threadItemAttachments" (click)="downloadAttachment(attachment.AttachmentId)" class="btn btn-outline-secondary btn-sm attachmentButton m-r-5 m-t-10">-->
            <!--              <i class="fas fa-file m-r-5"></i>-->
            <!--              <span>{{attachment.AttachmentName}}</span>-->
            <!--            </div>-->

          </div>

        </div>
      </div>
    </div>

  </div>
</app-blade-right>
