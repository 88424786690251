import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../shared/dialog/dialog";
import {ApiMassCommunicationService} from "../../../../services/api/mass-communication/mass-communication-api/mass-communication-api.service";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {MassCommunicationAddSubscribersComponent} from "./mass-communication-add-subscribers/mass-communication-add-subscribers.component";
import {MassCommunicationComponentDialog} from "../mass-communication-create-edit-dialog/mass-communication-create-edit-dialog.component";
import {InstanceParamsService} from "../../../../services/instance-params/instance-params.service";
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-mass-communication-view-dialog',
  templateUrl: './mass-communication-view-dialog.component.html',
  styleUrls: ['./mass-communication-view-dialog.component.css']
})
export class MassCommunicationViewDialogComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;
  instanceParams: any;


  newThreadContent = '';
  sendingInProgress = false;

  searchSubscribersTerm = '';

  loadedData;

  constructor(
    private apiMassCommunicationService: ApiMassCommunicationService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private instanceParamsService: InstanceParamsService,
    private sanitizer: DomSanitizer,
  ) {
  }


  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });

    if (this.data !== null) {
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  filteredSubscribers() {

    let filteredData = this.loadedData.Subscribers;
    let searchTerm = this.searchSubscribersTerm.toLocaleLowerCase();

    if (searchTerm) {
      filteredData = filteredData.filter(user => {
        return user.UserName.toString().toLocaleLowerCase().includes(searchTerm) || user.UserEmailAddress.toString().toLocaleLowerCase().includes(searchTerm)
      })
    }

    filteredData = filteredData.slice(0, 20);

    return filteredData;
  }

  editSubjectName(id) {
    // Replace null with Component
    this.dialogService.createDialog(MassCommunicationComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  removeAllSubscribers() {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiMassCommunicationService.topicDeleteAllSubscribers(this.loadedId).then(response => this.getData())
      }, cancelled => null)
  }

  addSubscribers() {
    // Replace null with Component
    this.dialogService.createDialog(MassCommunicationAddSubscribersComponent, this.loadedId, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  sendUpdate() {
    this.apiMassCommunicationService.sendUpdate(this.loadedId, this.newThreadContent).then(response => {
      this.newThreadContent = "";
      this.getData();
      }
    )
  }


  getData() {
    this.isLoading = true;
    this.apiMassCommunicationService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close() {
    // If you emit a value, it will refresh the parent that called the modal/blade. This instance we would like to do that!
    this.remove.emit("Saved");
  }

  save() {
    if (this.loadedId) {
      this.apiMassCommunicationService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiMassCommunicationService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
