<div>

  <div class="sdesk-refresh-bar" *ngIf="!statsLoading"></div>

  <div class="sdesk-menu-float-right">
    <div class="menu-item" *ngIf="this.showDashboardSettings == false" (click)="this.showDashboardSettings = true"><i
      class="fas fa-cog"></i></div>
    <div class="menu-item" (click)="refresh()"><i class="fas fa-sync-alt"></i></div>
  </div>

  <!--  <div *ngIf="userPermissions?.Admin && this.showDashboardSettings == false" class="SDeskSideNav divLink" >-->
  <!--    <a (click)="this.showDashboardSettings = true" id="about"><i class="fas fa-cog"></i>-->
  <!--    </a>-->
  <!--  </div>-->

  <app-dashboard-settings *ngIf="showDashboardSettings" (closeSettings)="this.closeSettings()"></app-dashboard-settings>

  <div id="dashboardTabs">
    <div class="row">
      <div class="col col-xl-1 d-none d-sm-none d-md-none d-lg-block">
        <div routerLink="/agent/dashboard/stats" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
             class="dashboardTile divLink text-center">
          <div class="icon"><i class="fas fa-chart-pie fa-3x graph"></i></div>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_Active" class="col col-xl-1">
        <div routerLink="/agent/dashboard/active" routerLinkActive="active" class="dashboardTile divLink text-center">
          <h5 class="value">{{this.statsData?.Open.Count}}</h5>
          <h6 class="label">Active</h6>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_Unassigned" class="col">
        <div routerLink="/agent/dashboard/unassigned" routerLinkActive="active" class="dashboardTile divLink">
          <div class="float-end icon d-none d-sm-none d-md-none d-lg-block"><i class="fas fa-question fa-3x amber"></i>
          </div>
          <h5 class="value">{{this.statsData?.UnassignedRequests.Count}}</h5>
          <h6 class="label">Unassigned</h6>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_Overdue" class="col">
        <div routerLink="/agent/dashboard/overdue" routerLinkActive="active" class="dashboardTile divLink">
          <div class="float-end icon d-none d-sm-none d-md-none d-lg-block"><i
            class="fas fa-hourglass-end fa-3x orange"></i></div>
          <h5 class="value">{{this.statsData?.SlaOverdueRequests.Count}}</h5>
          <h6 class="label">Overdue</h6>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_DueToday"
           class="col d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div routerLink="/agent/dashboard/duetoday" routerLinkActive="active" class="dashboardTile divLink">
          <div class="float-end icon d-none d-sm-none d-md-none d-lg-block"><i
            class="fas fa-calendar-alt fa-3x yellow"></i></div>
          <h5 class="value">{{this.statsData?.SlaDueToday.Count}}</h5>
          <h6 class="label">Due Today</h6>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_OpenedToday"
           class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div routerLink="/agent/dashboard/openedToday" routerLinkActive="active"
             class="dashboardTile divLink text-center">
          <h5 class="value">{{this.statsData?.OpenedToday.Count}}</h5>
          <h6 class="label">Opened <span>Today</span></h6>
        </div>
      </div>

      <div *ngIf="dashboardSettings && dashboardSettings.ShowTile_ResolvedToday"
           class="col-1 d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div routerLink="/agent/dashboard/resolvedToday" routerLinkActive="active"
             class="dashboardTile divLink text-center">
          <h5 class="value">{{this.statsData?.ResolvedToday.Count}}</h5>
          <h6 class="label">Resolved <span>Today</span></h6>
        </div>
      </div>

    </div>
  </div>

  <div id="dashboardContent">

    <div *ngIf="selectedTab == 'stats' && statsLoading" class="row sectionBox">
      <app-loading-spinner></app-loading-spinner>
    </div>

    <div *ngIf="selectedTab == 'stats' && !statsLoading">
      <div class="row">
        <div *ngIf="dashboardSettings && dashboardSettings.ShowGraph_Priority" class="col">
          <div class="sectionBox">
            <h5 class="label">Priority</h5>
            <app-highchart-pie [inputData]="this.statsData.PriorityPieChart"></app-highchart-pie>
          </div>
        </div>

        <div *ngIf="dashboardSettings && dashboardSettings.ShowGraph_Group" class="col">
          <div class="sectionBox">

            <h5 class="label">Group</h5>
            <app-highchart-column [inputData]="this.statsData.GroupBarChart"
                                  [inputColor]="'#81b29a'"></app-highchart-column>
          </div>
        </div>

        <div *ngIf="dashboardSettings && dashboardSettings.ShowGraph_Type" class="col">
          <div class="sectionBox">

            <h5 class="label">Request Type</h5>
            <app-highchart-column [inputData]="this.statsData.RequestTypeBarChart"
                                  [inputColor]="'#e29578'"></app-highchart-column>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="sectionBox">
            <h5 class="label">Trend</h5>
            <app-highchart-column *ngIf="!statsTrendLoading" [inputData]="this.statsTrendData.Trend" [inputColor]="'#557ea5'"></app-highchart-column>
          </div>
        </div>
      </div>
    </div>

    <!--  If Selected tab != stats then you are loading a ticket "view" (table of tickets)  -->
    <div *ngIf="selectedTab != 'stats'" class="row">

<!-- end paginator -->

      <div class="sectionBox p-0 col">
      <!-- start paginator -->

        <app-loading-spinner *ngIf="selectedTab != 'stats' && viewLoading"></app-loading-spinner>



        <app-ticket-bulk-update *ngIf="!viewLoading" [selectedTickets]="this.selectedTickets"
                                [ticketsData]="this.viewData"
                                (refreshData)="this.refresh(true); "></app-ticket-bulk-update>

        <div *ngIf="!viewLoading" class="table-responsive">
          <table class="table align-middle tableStyle">
            <thead>
            <tr>
              <th class="" scope="col">
                <div class="form-check">
                  <input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()"
                         class="form-check-input" type="checkbox" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault"></label>
                </div>
              </th>
              <th class="divLink" (click)="changeOrder('LocalRequestId')">
                Id <i *ngIf="orderTableBy == 'LocalRequestId'" [class.fa-arrow-up]="orderTableByAsc"
                        [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('RequestTypeName')">
                Type <i *ngIf="orderTableBy == 'RequestTypeName'" [class.fa-arrow-up]="orderTableByAsc"
                        [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('Requester')">
                Requester <i *ngIf="orderTableBy == 'Requester'" [class.fa-arrow-up]="orderTableByAsc"
                             [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="col-sm-4 divLink" (click)="changeOrder('Subject')">
                Subject <i *ngIf="orderTableBy == 'Subject'" [class.fa-arrow-up]="orderTableByAsc"
                           [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('Status')">
                Status <i *ngIf="orderTableBy == 'Status'" [class.fa-arrow-up]="orderTableByAsc"
                          [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('Priority')">
                Priority <i *ngIf="orderTableBy == 'Priority'" [class.fa-arrow-up]="orderTableByAsc"
                            [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="col-sm-1 divLink" (click)="changeOrder('Agent')">
                Agent <i *ngIf="orderTableBy == 'Agent'" [class.fa-arrow-up]="orderTableByAsc"
                         [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('DateTimeUpdated')">
                Updated <i *ngIf="orderTableBy == 'DateTimeUpdated'" [class.fa-arrow-up]="orderTableByAsc"
                           [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="divLink" (click)="changeOrder('DateTimeCreated')">
                Created <i *ngIf="orderTableBy == 'DateTimeCreated'" [class.fa-arrow-up]="orderTableByAsc"
                           [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>
              <th class="col-sm-1 divLink" (click)="changeOrder('NextSlaDueDateTime')">
                SLA <i *ngIf="orderTableBy == 'NextSlaDueDateTime'" [class.fa-arrow-up]="orderTableByAsc"
                       [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
              </th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let request of this.viewData " [class.sdesk-filter-unread]="!request.DateTimeLastRead"
                class="divLink">
              <td>
                <div class="form-check">
                  <input [ngModel]="this.selectedTickets.isSelected(request.Id)"
                         (change)="this.selectedTickets.toggle(request.Id)" class="form-check-input" type="checkbox"
                         id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault"></label>
                </div>
              </td>
              <td [routerLink]="['/agent/request/', request.Id]">{{request.LocalRequestId}}</td>
              <td [routerLink]="['/agent/request/', request.Id]">{{request.RequestTypeName}}

                <i *ngIf="request.ChatConversationId" [matTooltip]="'Chat'" [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'" class="fas fa-comment-lines" style="
    color: #557ea5;
    font-size: 21px;
    margin-left: 10px;
"></i></td>
              <td [routerLink]="['/agent/request/', request.Id]">{{request.Requester}}</td>
              <td [routerLink]="['/agent/request/', request.Id]" [innerHTML]="request.Subject"></td>
              <td [routerLink]="['/agent/request/', request.Id]">{{request.Status}}</td>
              <td [routerLink]="['/agent/request/', request.Id]">{{request.Priority}}</td>
              <td [routerLink]="['/agent/request/', request.Id]">
                <app-user-online-indicator [userId]="request.AgentId"></app-user-online-indicator>
                {{request.Agent}}
              </td>
              <td [routerLink]="['/agent/request/', request.Id]">
                <!--                {{ request.DateTimeUpdated | amTimeAgo }}-->
                <app-friendly-time [utcTimeData]="request.DateTimeUpdated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time>
              </td>
              <td [routerLink]="['/agent/request/', request.Id]">
                <!--                {{ request.DateTimeCreated | amTimeAgo }}-->
                <app-friendly-time [utcTimeData]="request.DateTimeCreated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time>
              </td>
              <td>
                <span *ngIf="request.NextSlaPaused" class="label m-0 SDeskSlaIndicator-Paused">Paused</span>
                <span *ngIf="!request.NextSlaPaused && request.NextSlaDueDateTime"
                      [className]="request.NextSlaOverdue? 'SDeskSlaIndicator-Red' : 'SDeskSlaIndicator-Green'"
                      class="label m-0">

                  {{ request.NextSlaDueDateTime | amTimeAgo }}

                  <!--                  <app-friendly-time [utcTimeData]="request.NextSlaDueDateTime"></app-friendly-time>-->
                </span>
              </td>


            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <ng-container>
      <span class="pagination-mytickets">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
      </span>
    </ng-container>
    </div>

  </div>
</div>
