

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminRequestTypeService} from "../../../../../services/api/admin/admin-request-type-api/admin-request-type-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminRequestTypesComponentDialog} from "./admin-request-types-create-edit-dialog/admin-request-types-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-request-types',
  templateUrl: './admin-request-types.html'
})
export class AdminRequestTypesComponent implements OnInit {

  data;
  isLoading = true;

  constructor(private apiAdminRequestTypeService: ApiAdminRequestTypeService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminRequestTypeService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminRequestTypesComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminRequestTypeService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
